// $secondary: red;

#ficusmapbox {
	width: 100%;
	height: 400px;
	z-index: 1;
}

.card.locationCard .card-header  {
	background-color: rgba(17, 24, 39, 0.1);
}

.locationlist .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #5982ad;
    border-color: #5982ad;
}

input[type="text"]::-webkit-input-placeholder,  input[type="email"]::-webkit-input-placeholder,  input[type="tel"]::-webkit-input-placeholder  {
  color: #bbb;
  font-weight: 100;
}

.col.tight {
	padding-right: calc(var(--bs-gutter-x) * 0.1);
	padding-left: calc(var(--bs-gutter-x) * 0.1);
}

.col.tight .card-body { 
	padding: 0.2rem 0.4rem
}

.navbar-ficus-dashboard {
    border-radius:0;
/*    background-color: #ebebeb; */
}

.navbar-nav.smallpills .nav-item {
	margin: 5px 5px;
}

.navbar-nav.smallpills .nav-item .nav-link {
	font-size: 0.7rem;
	padding: 0px 7px;
	border-radius:15px;
	background-color:#dcdcdc;
}

.navbar-nav.smallpills .nav-item .nav-link.active {
	background-color:#f5f5f5;
	border:solid 1px #878787;
	
}

.navbar-nav.smallpills .nav-item .nav-link {
	font-size: 0.7rem;
	padding: 0px 7px;
	border-radius:0px;
	border:none;
	background-color:transparent;
}

.navbar-nav.smallpills .nav-item .nav-link.active {
	background-color:transparent;
	border:none;
  border-bottom: 2px solid black;
  font-weight:500;
	
}

footer a.nav-link {
	padding: 0px;
	font-size: 0.7rem
}

footer {
	background-color: transparent;
}

main.content {
    margin-left: inherit !important;
    padding: 0 0.5rem 0 0.5rem;
}

.scroll-bsbody {
  display: block;
	height: 150px;
  overflow: scroll;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  
}

.scroll-bsheader {
	  display: block;
	  text-align:left;
}

.card table.studentstatus td, .card table.studentstatus th {
		padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0rem;
    font-size: .8rem;
}

.card table.studentstatus td, 
.card table.studentstatus th, 
table.studentstatus td,
table.studentstatus th {
		padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0rem;
    font-size: .6rem;
}

.card table.studentstatus,
table.studentstatus {
	table-layout: fixed !important;
}

.card.storycard, .card .card-header {
	color: white;	
	font-weight: normal;
	font-size: 0.9rem;

@media only screen and (max-width: 393px) {
  .storyicontop {
     display: block;
   }
   
  .storyiconright {
     display: none;
   }
}

@media only screen and (min-width: 394px) {
  .storyicontop {
     display: none;
   }
   
  .storyiconright {
     display: block;
   }
}

.storyicontop {
	
}

	
}.card.storycard {
	box-shadow: 1px 2px 3px 0px #7e7e7e;	
}
.card.storycard.inquiring, .card .card-header.inquiring, .icon-inquiring {
	background-color: steelblue;
	background-color: #1d5b8f;
	color: white;
}

.card.storycard.applying, .card .card-header.applying, .icon-applying {
	background-color: peru;
	background-color: #cb7520;
	background-color: #1b90b3;
	color: white;
}

.card.storycard.enrolled, .card .card-header.enrolled, .icon-enrolled {
	background-color: teal;
	background-color: #2b722b;
	color: white;
}

.icon-graduated {
	background-color: #332b72;
	color: white;
}

.icon-documents {
	background-color: #e78015;
	color: white;
}

.card .card-header.inquiring, .card .card-header.applying, .card .card-header.enrolled {
	background-color: transparent;
	color:black;
	border-bottom:none;
	font-size:initial;
	font-weight:500;
}

.table.dashboard thead tr th, .table.dashboard tbody tr td {
	font-size: 0.7rem;
	padding: 0.2rem 0.4rem;
	padding-left: 0.2rem
}

.table.statuslist thead tr th, .table.statuslist tbody tr td {
	font-size: 12px;
	padding: 0.1rem 0.1rem;
}

div.apexchartsstudentstatus svg.apexcharts-svg {
	overflow:visible;
}

.faq.card .accordion-button {
	font-weight: bold;
}

.faq.card .accordion-body {
	background-color: white;
}

.faq.card .accordion-body p {
	font-size: small;
}

.notification-bell.unread::before {
    background-color: #E11D48 !important;
    right: 12px !important;
    top: 4px !important;
    height: 0.75px !important;
    width: 0.75px !important;
    border: 3px solid #E11D48 !important;
}

td.statusindicator svg {
	stroke-width: 4;
}

div.studentstatus svg {
	stroke-width: 4;
	height: 1.4rem; 
	margin-top: -3px;
  border: 1px solid #9ca3af;
  margin-right: 3px;
}

div.studentstatus div.unverified svg {
	stroke-width: 3;
	height: 1.6rem; 
	margin-top: -3px;
  margin-right: 3px;
  border:none;
}

a.ficusblue {
	color: white;	
	font-weight: normal;
	font-size: 0.8rem;
	box-shadow: 1px 2px 3px 0px #7e7e7e;	
	background-color: #1d5b8f;
	border-color:#2b5272;
}

a.ficusgreen {
	color: white;	
	font-weight: normal;
	font-size: 0.8rem;
	box-shadow: 1px 2px 3px 0px #7e7e7e;	
	background-color: rgba(115,164,11,100%);
	border-color:rgba(115,164,11,100%);
}

.table.dashboard td.statusindicator {
	padding-top: 0.25rem;
}

span.dot {
	height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;

}

span.dot.red, span.dot.NonEmployee, span.dot.Terminated   {
  background-color: #c10606;
  border-color: #c10606;
  color:white;
}

span.dot.green, span.dot.Eligible,  {
  background-color: #005c00;
  border-color: #005c00;
  color:white;
}

button.verify.NonEmployee, button.verify.Terminated  {
  background-color: #c10606;
  border-color: #c10606;
  color:white;
  font-weight:500;
}

button.verify.Eligible {
  background-color: #005c00;
  border-color: #005c00;
  color:white;
  font-weight:500;
}

span.dot.yellow, span.dot.Unverified, button.verify.Unverified {
  background-color: #FED907;
  border-color: #FED907;
  color:#243030;
}

div.tooltip-inner {
    background-color: whitesmoke;
    border: 1px solid #4e4e4e;
    color: #001e4c;
    font-weight: 500;
    font-size: .8rem;	
}

button.verify .icon {
    stroke-width: 3;
    margin-top: -2px;
    margin-left: 0px;
    height: 0.9rem
}    

button.verify {
    color: white;
    font-weight: normal;
    margin-right:0.2rem;
    padding: 0.1rem 0.5rem 0.1rem 0.2rem !important;
    font-size: 0.7rem !important;
    border-radius: 0.25rem;

}   

.verifymodal {
	max-width:600px !important;	
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
	border-top-color: darkgray !important;
}

@media (min-width: 992px) {
	.container, .container-lg, .container-md, .container-sm {
	    max-width: 720px  !important;
	}
}

@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 720px  !important;
	}
}

@media (min-width: 1200px) {
	.container-xl, .container-lg, .container-md, .container-sm, .container {
	    max-width: 720px  !important;
	}
}

.leaflet-popup-content {
    margin: 12px 20px 8px 8px !important;
}

.list-group-timeline .list-group-item::after {
    margin-top: 0rem !important;

}

.timeline-detail {
	font-size: 0.8rem;
}

.form-check-input:checked[type=radio] {
    background-image: none !important;
}

#topic div.form-check label {
	font-weight: normal;
	margin-bottom: 0px;
}

.full-width-max {
	max-width: 100% !important;
}

div.xoverflow .table-responsive {
	overflow-x: scroll;
	padding-bottom: 0.3rem;
}

.verify.btn-check:checked + .verify.btn-primary, .verify.btn-check:active + .verify.btn-primary, .verify.btn-primary:active, .verify.btn-primary.active, .show > .verify.btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #c72727;
    border-color: #1d2030;
}


.tooltip-inner {
  font-size: 0.7rem !important;
  padding: .3rem !important;
}

.react-bootstrap-table tbody {
display:block;
height:200px;
overflow:auto;
}
.react-bootstrap-table thead{
display:table;
width:98.7%;
table-layout:fixed;
}

.react-bootstrap-table tbody tr {
display:table;
width:100%;
table-layout:fixed;
}

a.btn-cta {
  color: black;
  font-weight: 100;
	padding-top: 3px;
  padding-bottom: 3px;
 	font-size: 1.0rem;
	background-color: #ebeced;
  width: 100%;
  text-align: left;
  border-radius: 3rem;	
  border-color: #d6d9dd;
}

a.btn-cta:hover {
	background-color: #afacac;
	border-color: #909090;
}
a.btn-cta:focus {
	box-shadow: 0 0 0 0.1rem rgba(38, 43, 64, 0.25);
}
    
a.btn-cta img {
	height:2.5rem;
}

button.btn-socialmedia, a.btn-socialmedia {
	color: #68a543;
  background-color: transparent;
  border-color: #d6d9dd;
	padding: unset;
  height: 2.5rem;
  width: 2.5rem;
  line-height:2.1rem;
}

button.btn-socialmedia img, , a.btn-socialmedia img {
	height: 1.5rem;
}

a.dropdown-item.noaccess {
	color:lightgrey !important;
}

.table-condensed>tbody>tr>td, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>td, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>thead>tr>th {
    padding: 5px;
}

li.page-item {
	font-size: 0.8rem;
}